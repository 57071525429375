import { FunnelId } from 'constants/funnel';
import ExternalFunnelButton from 'components/Shared/ExternalFunnel/ExternalFunnelButton';
import Icon from 'components/ui/Icon';
import Image from 'components/ui/Image';
import { useAnalytics } from 'hooks/useAnalytics';
import { useTranslation } from 'hooks/useTranslation';
import Button from 'components/Qds/Button';
import styles from './Hero.module.scss';

const Hero = (): JSX.Element => {
  const { t, locale } = useTranslation();
  const { trackEvent } = useAnalytics();
  const qunosuiteUrl =
    locale === 'de' ? 'https://qunosuite.com/de' : 'https://qunosuite.com/en';

  return (
    <>
      <div className={styles.hero}>
        <div className={styles.heroGrid}>
          <div className={styles.heroLeftColumn}>
            <div className={styles.image}>
              <Image src="/v2/images/qs.webp" alt="" height={400} width={350} />
            </div>
            <div className={styles.intro}>
              <p className={styles.title}>{t('home.qunosuite.title')}</p>
              <p className={styles.subtitle}>{t('home.qunosuite.subtitle')}</p>
              <div className={styles.list}>
                <ul>
                  <li>
                    <Icon type="Tick" color="#A5CAD5" />
                    <p>{t('home.qunosuite.list.option1')}</p>
                  </li>
                  <li>
                    <Icon type="Tick" color="#A5CAD5" />
                    <p>{t('home.qunosuite.list.option2')}</p>
                  </li>
                  <li>
                    <Icon type="Tick" color="#A5CAD5" />
                    <p>{t('home.qunosuite.list.option3')}</p>
                  </li>
                </ul>
              </div>
              <Button
                className={styles.checkQuonosuiteButton}
                fullWidth="mobile"
                size="large"
                shadow={false}
                onClick={() => {
                  trackEvent({
                    category: 'CTA',
                    action: 'CTA|Click',
                    label: 'CTA|Home|Hero|GetStarted',
                  });
                }}
                isLink
                href={`${qunosuiteUrl}?utm_source=qunomedical&utm_medium=website&utm_campaign=homepage&utm_content=split`}
                rel="nofollow noindex"
              >
                <span>{t('home.qunosuite.cta')}</span>
              </Button>
              <div />
            </div>
          </div>
          <div className={styles.heroRightColumn}>
            <div className={styles.image}>
              <Image src="/v2/images/qm.webp" alt="" height={700} width={310} />
            </div>
            <div className={styles.intro}>
              <p className={styles.title}>{t('home.qunomedical.title')}</p>
              <p className={styles.subtitle}>
                {t('home.qunomedical.subtitle')}
              </p>
              <div className={styles.list}>
                <ul>
                  <li>
                    <Icon type="Tick" color="#155263" />
                    <p>{t('home.qunomedical.list.option1')}</p>
                  </li>
                  <li>
                    <Icon type="Tick" color="#155263" />
                    <p>{t('home.qunomedical.list.option2')}</p>
                  </li>
                  <li>
                    <Icon type="Tick" color="#155263" />
                    <p>{t('home.qunomedical.list.option3')}</p>
                  </li>
                </ul>
              </div>
              <ExternalFunnelButton
                funnelId={FunnelId.CONTACT_FORM}
                renderButton={(openFunnel) => {
                  return (
                    <Button
                      className={styles.getStartedButton}
                      fullWidth="mobile"
                      size="large"
                      shadow={false}
                      onClick={() => {
                        trackEvent({
                          category: 'CTA',
                          action: 'CTA|Click',
                          label: 'CTA|Home|Hero|GetStarted',
                        });

                        openFunnel();
                      }}
                    >
                      <span>{t('home.qunomedical.cta')}</span>
                    </Button>
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
